<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="bannerList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            label="资源"
            align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.isImage==1" class="table-img" :src="scope.row.sourceUrl">
            <video v-else :src="scope.row.sourceUrl" class="table-video"></video>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="type"
            label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.isImage == 1">图片</span>
            <span v-else>视频</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="weight"
            label="权重">
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addBanner">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="preview(scope.row)">预览</el-link>
            <el-link :underline="false" type="danger" @click="deleteBanner(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="预览"
        :visible.sync="dialogVisible"
        width="80%">
      <img v-if="previewInfo.isImage==1" class="preview-img" :src="previewInfo.sourceUrl">
      <video v-else class="preview-video" controls>
        <source :src="previewInfo.sourceUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="类型">
          <el-radio-group v-model="addForm.type">
            <el-radio label="图片"></el-radio>
            <el-radio label="视频"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文件">
          <img-upload :url="addForm.url" @change="changeUrl"></img-upload>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="addForm.weight"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="submit">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getBanner, addBanner, delBanner} from "@/plugins/api";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'Banner',
  components: {ImgUpload},
  data() {
    return {
      bannerList: [
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/bg.jpg',
          type: 1,
          weight: 10,
        },
        {
          id: 2,
          url: 'http://www.simpuretech.com/static/images/home/home4.mp4',
          type: 2,
          weight: 5,
        },
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/bg.jpg',
          type: 1,
          weight: 10,
        },
        {
          id: 2,
          url: 'http://www.simpuretech.com/static/images/home/home4.mp4',
          type: 2,
          weight: 5,
        },
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/bg.jpg',
          type: 1,
          weight: 10,
        },
        {
          id: 2,
          url: 'http://www.simpuretech.com/static/images/home/home4.mp4',
          type: 2,
          weight: 5,
        },
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/bg.jpg',
          type: 1,
          weight: 10,
        },
        {
          id: 2,
          url: 'http://www.simpuretech.com/static/images/home/home4.mp4',
          type: 2,
          weight: 5,
        },
        {
          id: 1,
          url: 'http://www.simpuretech.com/static/images/product/bg.jpg',
          type: 1,
          weight: 10,
        },
        {
          id: 2,
          url: 'http://www.simpuretech.com/static/images/home/home4.mp4',
          type: 2,
          weight: 5,
        },
      ],
      previewInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      addForm: {
        type: '',
        url: '',
        weight: 10,
      },
      fileList: []
    }
  },
  methods: {
    preview(row) {
      this.previewInfo = row;
      this.dialogVisible = true;
    },
    addBanner() {
      this.addDialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    changeUrl(url) {
      this.addForm.url = url;
    },
    deleteBanner (id) {
      delBanner({id})
      this.getBanner()
    },
    async getBanner() {
      let res = await getBanner()
      this.bannerList = res;
    },
    async submit() {
      let data = {
        source_url: this.addForm.url,
        is_video: this.addForm.type == '视频' ? 1 : 0,
        is_image: this.addForm.type == '图片' ? 1 : 0,
        weight: this.addForm.weight,
      }
      await addBanner(data);
      this.getBanner()
      this.addForm = {
        type: '',
        url: '',
        weight: 10,
      };
      this.addDialogVisible = false;
    }
  },
  created() {
    this.getBanner()
  }
}
</script>

<style scoped>
.table-img, .table-video {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}
.preview-img, .preview-video {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
